import { TfiFacebook } from "react-icons/tfi";
import { FaTwitter } from "react-icons/fa";
import { FaMessage } from "react-icons/fa6";
import { FaTelegramPlane } from "react-icons/fa";
import { useTranslation } from "react-i18next";
import { useState } from "react";
import ImageModal from "../Modal/ImageModal";
import certCS from "../../images/certs/cert_cs.png";
import certPoland from "../../images/certs/cert_poland.png";

const Footer = () => {
  let currentDate = new Date();
  const { t } = useTranslation();
  const [modalImage, setModalImage] = useState(null);

  const openModal = (image, alt) => {
    setModalImage({ src: image, alt });
  };

  const closeModal = () => {
    setModalImage(null);
  };

  return (
    <>
      <footer className="relative z-10 bg-white dark:bg-black h-full border-t-[1px] border-purple-500">
        <div className="mx-12 2xl:mx-32 py-5 min-h-[140px] h-full flex flex-col lg:flex-row gap-8">
          <div className="flex flex-col items-center lg:items-start gap-6 lg:w-[200px]">
            <div className="text-gray-400 text-center lg:text-left">
              <p>New Reality GC 2022-{currentDate.getFullYear()}</p>
              <p>All rights reserved</p>
            </div>
            
            <div className="flex items-center gap-5 2xl:gap-10">
              <a href="/" aria-label="social-link">
                <TfiFacebook className="fill-gray-500 dark:fill-gray-300 h-[18px] w-[18px] hover:fill-dark dark:hover:fill-white" />
              </a>
              <a href="/" aria-label="social-link">
                <FaTwitter className="fill-gray-500 dark:fill-gray-300 h-[18px] w-[18px] hover:fill-dark dark:hover:fill-white" />
              </a>
              <a href="https://newreality.cc/index.html" aria-label="social-link">
                <FaMessage className="fill-gray-500 dark:fill-gray-300 h-[18px] w-[18px] hover:fill-dark dark:hover:fill-white" />
              </a>
              <a href="https://t.me/New_Reality_Bond_Bot" aria-label="social-link">
                <FaTelegramPlane className="fill-gray-500 dark:fill-gray-300 h-[18px] w-[18px] hover:fill-dark dark:hover:fill-white" />
              </a>
            </div>

            <div className="flex items-center gap-4">
              <button 
                onClick={() => openModal(certCS, "CS Certificate")}
                className="cursor-pointer hover:opacity-80 transition-opacity"
              >
                <img src={certCS} alt="CS Certificate" className="h-16" />
              </button>
              <button 
                onClick={() => openModal(certPoland, "Poland Certificate")}
                className="cursor-pointer hover:opacity-80 transition-opacity"
              >
                <img src={certPoland} alt="Poland Certificate" className="h-16" />
              </button>
            </div>
          </div>

          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-x-8 gap-y-2 text-black dark:text-white text-sm flex-1">
            <a href="/termsOfUse" className="hover:text-purple-500">{t("footer.links.1")}</a>
            <a href="/privacyNotice" className="hover:text-purple-500">{t("footer.links.2")}</a>
            <a href="/riskWarning" className="hover:text-purple-500">{t("footer.links.3")}</a>
            <a href="/listOfProhibitedCountries" className="hover:text-purple-500">{t("footer.links.4")}</a>
            <a href="/CookieNotice" className="hover:text-purple-500">{t("footer.links.5")}</a>
            <a href="/About" className="hover:text-purple-500">{t("footer.links.6")}</a>
            <a href="/Legal" className="hover:text-purple-500">{t("footer.links.7")}</a>
            <a href="/Contact" className="hover:text-purple-500">{t("footer.links.8")}</a>
            <a href="/TariffsPage" className="hover:text-purple-500">{t("footer.links.9")}</a>
            <a href="/TariffsTermPage" className="hover:text-purple-500">{t("footer.links.10")}</a>
            <a href="/BlockchainPage" className="hover:text-purple-500">{t("footer.links.11")}</a>
            <a href="/Forvalidators" className="hover:text-purple-500">{t("footer.links.12")}</a>
            <a href="/CryptoATMs" className="hover:text-purple-500">{t("footer.links.13")}</a>
            <a href="https://wallet.newreality.club/stock" className="hover:text-purple-500">{t("footer.links.14")}</a>
            <a href="https://wallet.newreality.club/p2p/offers" className="hover:text-purple-500">{t("footer.links.15")}</a>
            <a href="/popularTokens" className="hover:text-purple-500">{t("footer.links.16")}</a>
            <a href="/Merchant" className="hover:text-purple-500">{t("footer.links.17")}</a>
            <a href="/MerchantConfig" className="hover:text-purple-500">{t("footer.links.18")}</a>
            <a href="https://docs.newreality.bond/api/" className="hover:text-purple-500">{t("footer.links.19")}</a>
            <a href="/Presale" className="hover:text-purple-500">{t("footer.links.20")}</a>
            <a href="/Createtoken" className="hover:text-purple-500">{t("footer.links.21")}</a>
            <a href="/FormPage" className="hover:text-purple-500">{t("footer.links.22")}</a>
            <a href="/FormPage" className="hover:text-purple-500">{t("footer.links.23")}</a>
            <a href="ExchangeDesc" className="hover:text-purple-500">{t("footer.links.24")}</a>
          </div>
        </div>
        <div className="absolute left-0 bottom-3 z-[-1]">
          <svg width="79" height="94" viewBox="0 0 79 94" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect
              opacity="0.3"
              x="-41"
              y="26.9426"
              width="66.6675"
              height="66.6675"
              transform="rotate(-22.9007 -41 26.9426)"
              fill="url(#paint0_linear_94:889)"
            />
            <rect
              x="-41"
              y="26.9426"
              width="66.6675"
              height="66.6675"
              transform="rotate(-22.9007 -41 26.9426)"
              stroke="url(#paint1_linear_94:889)"
              strokeWidth="0.7"
            />
            <path
              opacity="0.3"
              d="M50.5215 7.42229L20.325 1.14771L46.2077 62.3249L77.1885 68.2073L50.5215 7.42229Z"
              fill="url(#paint2_linear_94:889)"
            />
            <path
              d="M50.5215 7.42229L20.325 1.14771L46.2077 62.3249L76.7963 68.2073L50.5215 7.42229Z"
              stroke="url(#paint3_linear_94:889)"
              strokeWidth="0.7"
            />
            <path
              opacity="0.3"
              d="M17.9721 93.3057L-14.9695 88.2076L46.2077 62.325L77.1885 68.2074L17.9721 93.3057Z"
              fill="url(#paint4_linear_94:889)"
            />
            <path
              d="M17.972 93.3057L-14.1852 88.2076L46.2077 62.325L77.1884 68.2074L17.972 93.3057Z"
              stroke="url(#paint5_linear_94:889)"
              strokeWidth="0.7"
            />
            <defs>
              <linearGradient
                id="paint0_linear_94:889"
                x1="-41"
                y1="21.8445"
                x2="36.9671"
                y2="59.8878"
                gradientUnits="userSpaceOnUse"
              >
                <stop stopColor="#4A6CF7" stopOpacity="0.62" />
                <stop offset="1" stopColor="#4A6CF7" stopOpacity="0" />
              </linearGradient>
              <linearGradient
                id="paint1_linear_94:889"
                x1="25.6675"
                y1="95.9631"
                x2="-42.9608"
                y2="20.668"
                gradientUnits="userSpaceOnUse"
              >
                <stop stopColor="#4A6CF7" stopOpacity="0" />
                <stop offset="1" stopColor="#4A6CF7" stopOpacity="0.51" />
              </linearGradient>
              <linearGradient
                id="paint2_linear_94:889"
                x1="20.325"
                y1="-3.98039"
                x2="90.6248"
                y2="25.1062"
                gradientUnits="userSpaceOnUse"
              >
                <stop stopColor="#4A6CF7" stopOpacity="0.62" />
                <stop offset="1" stopColor="#4A6CF7" stopOpacity="0" />
              </linearGradient>
              <linearGradient
                id="paint3_linear_94:889"
                x1="18.3642"
                y1="-1.59742"
                x2="113.9"
                y2="80.6826"
                gradientUnits="userSpaceOnUse"
              >
                <stop stopColor="#4A6CF7" stopOpacity="0" />
                <stop offset="1" stopColor="#4A6CF7" stopOpacity="0.51" />
              </linearGradient>
              <linearGradient
                id="paint4_linear_94:889"
                x1="61.1098"
                y1="62.3249"
                x2="-8.82468"
                y2="58.2156"
                gradientUnits="userSpaceOnUse"
              >
                <stop stopColor="#4A6CF7" stopOpacity="0.62" />
                <stop offset="1" stopColor="#4A6CF7" stopOpacity="0" />
              </linearGradient>
              <linearGradient
                id="paint5_linear_94:889"
                x1="65.4236"
                y1="65.0701"
                x2="24.0178"
                y2="41.6598"
                gradientUnits="userSpaceOnUse"
              >
                <stop stopColor="#4A6CF7" stopOpacity="0" />
                <stop offset="1" stopColor="#4A6CF7" stopOpacity="0.51" />
              </linearGradient>
            </defs>
          </svg>
        </div>
      </footer>

      <ImageModal
        isOpen={modalImage !== null}
        onClose={closeModal}
        imageSrc={modalImage?.src}
        altText={modalImage?.alt}
      />
    </>
  );
};

export default Footer;
