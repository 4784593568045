import LangSwitcher from "./../LangSwitcher/LangSwitcher";
import ThemeSwitcher from "../ThemeSwitcher/ThemeSwitcher";
import icon7 from "../../images/icon7.svg";
import { useLocation } from "react-router-dom";
import MobileMenuButton from "../MobileMenuButton/MobileMenuButton";
import { useTranslation } from "react-i18next";

const Header = ({ isMobileMenuOpen, toggleMobileMenu }) => {
  const { t } = useTranslation();
  const scrollToSection = (id) => {
    const element = document.getElementById(id);
    if (element) {
      element.scrollIntoView({ behavior: "smooth" });
    }
  };

  const location = useLocation();

  const handleButtonClick = () => {
    if (location.pathname === "/") {
      scrollToSection("marketplace");
    } else {
      window.location.href = "/";
    }
  };

  return (
    <nav
      className={` fixed top-0 left-0 h-[72px] bg-white bg-opacity-100 dark:bg-black/50 z-50 px-3 flex w-full items-center justify-around font-body ${"z-[9999] dark:bg-black/50 dark:bg-opacity-80 backdrop-blur-sm transition dark:bg-gray-dark dark:shadow-sticky-dark"}`}
    >
      <div>
        <a href="/" className="flex items-center space-x-3 rtl:space-x-reverse">
          <img src={icon7} className="w-[30px] h-[30px] 2xl:w-[50px] 2xl:h-[50px]" alt=""></img>
          <span className=" font-light top-[5px] text-lg 2xl:text-2xl dark:text-white">New Reality</span>
        </a>
      </div>
      <div className="px-4 xl:mr-12">
        <div className="hidden  w-full xl:block md:w-auto" id="navbar-default">
          <ul className="font-normal flex flex-col gap-[20px] items-center  border border-gray-100 rounded-lg md:flex-row  md:mt-0 md:border-0 ">
            <li className="relative group">
              <a
                href="/"
                className="block px-4 py-2 text-xl text-dark hover:text-primary dark:text-white/70 dark:hover:text-white"
                aria-current="page"
              >
                {t("header.home")}
              </a>
            </li>
            <li className="relative group">
              <p className="flex items-center justify-between py-2 text-xl cursor-pointer text-dark group-hover:text-primary dark:text-white/70 dark:group-hover:text-white lg:mr-0 lg:inline-flex lg:py-6 lg:px-0">
                {t("header.exchange.1")}
                <span className="pl-3">
                  <svg width="25" height="24" viewBox="0 0 25 24">
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M6.29289 8.8427C6.68342 8.45217 7.31658 8.45217 7.70711 8.8427L12 13.1356L16.2929 8.8427C16.6834 8.45217 17.3166 8.45217 17.7071 8.8427C18.0976 9.23322 18.0976 9.86639 17.7071 10.2569L12 15.964L6.29289 10.2569C5.90237 9.86639 5.90237 9.23322 6.29289 8.8427Z"
                      fill="currentColor"
                    />
                  </svg>
                </span>
              </p>
              <div
                className={`submenu relative top-full left-0 rounded-sm bg-white transition-[top] duration-300 group-hover:opacity-100 dark:bg-dark lg:invisible lg:absolute lg:top-[110%] lg:block lg:w-[250px] lg:p-4 lg:opacity-0 lg:shadow-lg lg:group-hover:visible lg:group-hover:top-full `}
              >
                <a
                  href={"https://wallet.newreality.club/stock"}
                  className="block rounded py-2.5 text-sm text-dark hover:text-primary dark:text-white/70 dark:hover:text-white lg:px-3"
                >
                  {t("header.exchange.2")}
                </a>
                <a
                  href={"https://wallet.newreality.club/p2p/offers"}
                  className="block rounded py-2.5 text-sm text-dark hover:text-primary dark:text-white/70 dark:hover:text-white lg:px-3"
                >
                  {t("header.exchange.3")}
                </a>
              </div>
            </li>
            <li className="relative group">
              <button
                onClick={handleButtonClick}
                className="block px-4 py-2 text-xl text-dark hover:text-primary dark:text-white/70 dark:hover:text-white"
              >
                {t("header.openSale")}
              </button>
            </li>

            <li className="relative group">
              <p className="flex items-center justify-between py-2 text-xl cursor-pointer text-dark group-hover:text-primary dark:text-white/70 dark:group-hover:text-white lg:mr-0 lg:inline-flex lg:py-6 lg:px-0">
                {t("header.services")}
                <span className="pl-3">
                  <svg width="25" height="24" viewBox="0 0 25 24">
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M6.29289 8.8427C6.68342 8.45217 7.31658 8.45217 7.70711 8.8427L12 13.1356L16.2929 8.8427C16.6834 8.45217 17.3166 8.45217 17.7071 8.8427C18.0976 9.23322 18.0976 9.86639 17.7071 10.2569L12 15.964L6.29289 10.2569C5.90237 9.86639 5.90237 9.23322 6.29289 8.8427Z"
                      fill="currentColor"
                    />
                  </svg>
                </span>
              </p>
              <div
                className={`submenu relative top-full left-0 rounded-sm bg-white transition-[top] duration-300 group-hover:opacity-100 dark:bg-dark lg:invisible lg:absolute lg:top-[110%] lg:block lg:w-[250px] lg:p-4 lg:opacity-0 lg:shadow-lg lg:group-hover:visible lg:group-hover:top-full `}
              >
                <a
                  href={"https://wallet.newreality.club"}
                  className="block rounded py-2.5 text-sm text-dark hover:text-primary dark:text-white/70 dark:hover:text-white lg:px-3"
                >
                  {t("header.servicesItem.1")}
                </a>
                {/* <a
                  href={"https://newreality.fund"}
                  className="block rounded py-2.5 text-sm text-dark hover:text-primary dark:text-white/70 dark:hover:text-white lg:px-3"
                >
                  {t("header.servicesItem.2")}
                </a> */}
                <a
                  href={"https://wallet.newreality.club/stock"}
                  className="block rounded py-2.5 text-sm text-dark hover:text-primary dark:text-white/70 dark:hover:text-white lg:px-3"
                >
                  {t("header.servicesItem.10")}
                </a>
                <a
                  href={"https://wallet.newreality.club/p2p/offers"}
                  className="block rounded py-2.5 text-sm text-dark hover:text-primary dark:text-white/70 dark:hover:text-white lg:px-3"
                >
                  {t("header.servicesItem.3")}
                </a>
                {/* <a
                  href={"https://bond.money"}
                  className="block rounded py-2.5 text-sm text-dark hover:text-primary dark:text-white/70 dark:hover:text-white lg:px-3"
                >
                  {t("header.servicesItem.4")}
                </a> */}
                <a
                  href={"/Forvalidators"}
                  className="block rounded py-2.5 text-sm text-dark hover:text-primary dark:text-white/70 dark:hover:text-white lg:px-3"
                >
                  {t("header.servicesItem.5")}
                </a>
                <a
                  href={"/CryptoATMs"}
                  className="block rounded py-2.5 text-sm text-dark hover:text-primary dark:text-white/70 dark:hover:text-white lg:px-3"
                >
                  {t("header.servicesItem.9")}
                </a>
                <a
                  href={"/Createtoken"}
                  className="block rounded py-2.5 text-sm text-dark hover:text-primary dark:text-white/70 dark:hover:text-white lg:px-3"
                >
                  {t("header.servicesItem.8")}
                </a>
                <a
                  href={"/popularTokens"}
                  className="block rounded py-2.5 text-sm text-dark hover:text-primary dark:text-white/70 dark:hover:text-white lg:px-3"
                >
                  {t("header.servicesItem.11")}
                </a>
                <a
                  href={"/Presale"}
                  className="block rounded py-2.5 text-sm text-dark hover:text-primary dark:text-white/70 dark:hover:text-white lg:px-3"
                >
                  {t("header.servicesItem.12")}
                </a>
                <a
                  href={"/Merchant"}
                  className="block rounded py-2.5 text-sm text-dark hover:text-primary dark:text-white/70 dark:hover:text-white lg:px-3"
                >
                  {t("header.servicesItem.13")}
                </a>
                <a
                  href={"https://explorer.newreality.club"}
                  className="block rounded py-2.5 text-sm text-dark hover:text-primary dark:text-white/70 dark:hover:text-white lg:px-3"
                >
                  {t("header.servicesItem.6")}
                </a>
              </div>
            </li>

            <li className="relative group">
              <p className="flex items-center justify-between py-2 text-xl cursor-pointer text-dark group-hover:text-primary dark:text-white/70 dark:group-hover:text-white lg:mr-0 lg:inline-flex lg:py-6 lg:px-0">
                {t("header.knowledge")}
                <span className="pl-3">
                  <svg width="25" height="24" viewBox="0 0 25 24">
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M6.29289 8.8427C6.68342 8.45217 7.31658 8.45217 7.70711 8.8427L12 13.1356L16.2929 8.8427C16.6834 8.45217 17.3166 8.45217 17.7071 8.8427C18.0976 9.23322 18.0976 9.86639 17.7071 10.2569L12 15.964L6.29289 10.2569C5.90237 9.86639 5.90237 9.23322 6.29289 8.8427Z"
                      fill="currentColor"
                    />
                  </svg>
                </span>
              </p>
              <div
                className={`submenu relative top-full left-0 rounded-sm bg-white transition-[top] duration-300 group-hover:opacity-100 dark:bg-dark lg:invisible lg:absolute lg:top-[110%] lg:block lg:w-[250px] lg:p-4 lg:opacity-0 lg:shadow-lg lg:group-hover:visible lg:group-hover:top-full `}
              >
                <a
                  href={"/TermsPage"}
                  className="block rounded py-2.5 text-sm text-dark hover:text-primary dark:text-white/70 dark:hover:text-white lg:px-3"
                >
                  {t("header.knowledgeItem.1")}
                </a>
                <a
                  href={"/СlassifyingPage"}
                  className="block rounded py-2.5 text-sm text-dark hover:text-primary dark:text-white/70 dark:hover:text-white lg:px-3"
                >
                  {t("header.knowledgeItem.2")}
                </a>
                <a
                  href={"/ARTPage"}
                  className="block rounded py-2.5 text-sm text-dark hover:text-primary dark:text-white/70 dark:hover:text-white lg:px-3"
                >
                  {t("header.knowledgeItem.3")}
                </a>
                <a
                  href={"/EmtPage"}
                  className="block rounded py-2.5 text-sm text-dark hover:text-primary dark:text-white/70 dark:hover:text-white lg:px-3"
                >
                  {t("header.knowledgeItem.4")}
                </a>
                <a
                  href={"/UnboundPage"}
                  className="block rounded py-2.5 text-sm text-dark hover:text-primary dark:text-white/70 dark:hover:text-white lg:px-3"
                >
                  {t("header.knowledgeItem.5")}
                </a>
                <a
                  href={"/RegulationPage"}
                  className="block rounded py-2.5 text-sm text-dark hover:text-primary dark:text-white/70 dark:hover:text-white lg:px-3"
                >
                  {t("header.knowledgeItem.6")}
                </a>
                <a
                  href={"/Createtoken"}
                  className="block rounded py-2.5 text-sm text-dark hover:text-primary dark:text-white/70 dark:hover:text-white lg:px-3"
                >
                  {t("header.knowledgeItem.7")}
                </a>
                <a
                  href={"/MerchantConfig"}
                  className="block rounded py-2.5 text-sm text-dark hover:text-primary dark:text-white/70 dark:hover:text-white lg:px-3"
                >
                  {t("header.knowledgeItem.8")}
                </a>
                <a
                  href={"https://docs.newreality.bond/api/"}
                  className="block rounded py-2.5 text-sm text-dark hover:text-primary dark:text-white/70 dark:hover:text-white lg:px-3"
                >
                  {t("header.knowledgeItem.9")}
                </a>
                <a
                  href={"/faq"}
                  className="block rounded py-2.5 text-sm text-dark hover:text-primary dark:text-white/70 dark:hover:text-white lg:px-3"
                >
                  {t("header.knowledgeItem.10")}
                </a>
                <a
                  href={"/TariffsPage"}
                  className="block rounded py-2.5 text-sm text-dark hover:text-primary dark:text-white/70 dark:hover:text-white lg:px-3"
                >
                  {t("header.knowledgeItem.11")}
                </a>
                <a
                  href={"/ExchangeDesc"}
                  className="block rounded py-2.5 text-sm text-dark hover:text-primary dark:text-white/70 dark:hover:text-white lg:px-3"
                >
                  {t("header.knowledgeItem.12")}
                </a>
                <a
                  href={"/TravelRule"}
                  className="block rounded py-2.5 text-sm text-dark hover:text-primary dark:text-white/70 dark:hover:text-white lg:px-3"
                >
                  {t("header.knowledgeItem.13")}
                </a>
              </div>
            </li>
          </ul>
        </div>
      </div>
      <div className="flex items-center gap-3 lg:gap-8">
        <a className="p-[2px] relative hidden xl:block" href="https://wallet.newreality.club">
          <div className="px-2 py-2 xl:py-4 xl:px-8  inset-0 bg-blue-600 rounded-lg relative group transition duration-300 ease-in-out text-white hover:text-white zoom">
            {t("header.button")}
          </div>
        </a>
        <ThemeSwitcher />
        <LangSwitcher />
        <MobileMenuButton onClick={toggleMobileMenu} isOpen={isMobileMenuOpen} />
      </div>
    </nav>
  );
};

export default Header;
