import React from 'react';

const ImageModal = ({ isOpen, onClose, imageSrc, altText }) => {
  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 z-50 flex items-center justify-center p-4 bg-black bg-opacity-70"
         onClick={onClose}>
      <div className="relative max-w-[90vw] max-h-[90vh]"
           onClick={e => e.stopPropagation()}>
        <button
          className="absolute -top-4 -right-4 w-8 h-8 flex items-center justify-center rounded-full bg-white text-black hover:bg-gray-200"
          onClick={onClose}
        >
          ×
        </button>
        <img
          src={imageSrc}
          alt={altText}
          className="max-w-full max-h-[85vh] object-contain"
        />
      </div>
    </div>
  );
};

export default ImageModal;
